import React from 'react'
import styled from 'styled-components'
import sukatePicture from 'assets/sb/sukate.jpeg'
import bezebohPicture from 'assets/sb/bezeboh.png'

const FounderStyle = styled.div<{user?: 'sukate' | 'bezeboh'}>`
    width: 30vw;
    .top{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin: 0 0 10px;
        .text{
            display: flex;
            flex-direction: column;
            h5{
                font-size: 1.25vw;
                line-height: 1.5vw;
                font-weight: medium;
                display: inline-block;
                color: ${({theme}) => theme.colors.sbGreen};
            }
        }
        .img{
            width: 17.5vw;
            height: 17vw;
            background-color: ${({user,theme}) => user === 'sukate' ? theme.colors.sbGreen : theme.colors.sbYellow};
            position: relative;
            top:  ${({user}) => user === 'sukate' ? '0' : '-10px'};
            img{
                width: 100%;
                height: 100%;
                position: absolute;
                top: -10px;
                top: ${({user}) => user === 'sukate' ? '-10px' : '10px'};
                left: -10px;
            }
        }
    }
    p{
        font-size: .9vw;
        line-height: 1.25vw;
        width: 28vw;
        color: ${({theme}) => theme.colors.sbDarkgrey}
    }
    @media only screen and (min-width: 1920px){
        width: 550px;
        .top{
            .text{
                h5{
                    font-size: 24px;
                    line-height: 30px;
                }
            }
            .img{
                width: 337px;
                height: 326px;
            }
        }
        p{
            font-size: 18px;
            line-height: 24px;
            width: 535px;
            color: ${({theme}) => theme.colors.sbDarkgrey}
        }
    }
    @media only screen and (max-width: 767px){
        width: 100%;
        .top{
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: space-between;
            margin: 0 0 10px;
            .text{
                flex-direction: row;
                h5{
                    font-size: 16px;
                    margin: 24px 0 12px;
                    &:nth-of-type(2){
                        margin-left:4px;
                    }
                }
            }
            .img{
                position: relative;
                left: 10px;
                width: calc( (100vw - (2 * ${({theme}) => theme.mobilePadding})) - 10px );
                height: calc( ((100vw - (2 * ${({theme}) => theme.mobilePadding})) - 10px) * .97 );
            }
        }
        p{
            font-size: 14px;
            line-height: 18px;
            width: 100%;
        }
    }
`;

export const Founder = ({founder}: {founder: 'sukate' | 'bezeboh'}) => {
    const FoundersInfo = {
        sukate: {
            name: 'Henry Sukate Eho',
            img: sukatePicture,
            qualification: 'Henry Eho holds a BSc in Economics, MA in Marketing Management and Postgraduate Diploma from the Chartered Institute of Marketing. Over a period of 10 years, Henry has accumulated extensive experience in the fields of Data Science, Data Analytics and Data Management across several industries including financial services, Digital Marketing, Consulting, FMCG, Charity and Travel.'
        },
        bezeboh: {
            name: 'Gaston Bezeboh Fornimoh',
            img: bezebohPicture,
            qualification: 'Gaston Fornimoh holds a BSc in Economics and an MSc in Finance and Economics. In the last 10 years, Gaston has accumulated extensive experience working in FMCG and Financial Services sector. During this period, Gaston has managed and delivered on projects within Risk and Compliance, often working in the First and Second Line of Defence within Financial Services.'
        }
    }
    return (
        <FounderStyle user={founder}>
            <div className="top">
                <div className="text">
                    {/* <h5>{FoundersInfo[founder].name.split(" ")[0]} {FoundersInfo[founder].name.split(" ")[1]}</h5> */}
                    <h5>{ `${FoundersInfo[founder].name.split(" ")[0]} ${FoundersInfo[founder].name.split(" ")[1]}`}</h5>
                    <h5>{FoundersInfo[founder].name.split(" ")[2]}</h5>
                </div>
                <div className="img">
                    <img src={FoundersInfo[founder].img} alt={founder} />
                </div>
            </div>
            <p>{FoundersInfo[founder].qualification}</p>
        </FounderStyle>
    )
}
