import React from 'react';
import {GlobalStyle, Theme as ThemeProvider} from 'config/styles'
import { Approutes } from './Approutes'

function App() {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider>
        <Approutes />
      </ThemeProvider>
    </>
  );
}

export default App;
