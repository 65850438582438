import React from 'react'
import Main from './home-components/main'
import About from './home-components/about'
import Subsidiary from './home-components/subsidiary'
import Services from './home-components/services'
import Founders from './home-components/founders'

export const Home = () => {
    return (
        <>
            <Main />
            <About />
            <Subsidiary />
            <Services />
            <Founders />
        </>
    )
}
