import React from 'react'
import styled from 'styled-components'
import arrowGreen from 'assets/utils/arrowGreen.png'

const Subsidiary = () => (
    <SubsidiaryStyle id="subsidiaries">
        <h1>Our Subsidiaries</h1>
        <div className="subsidiaries">
            <div className="unit">
                <h3>Savanna Technologies</h3>
                <h3>Ltd</h3>
                <p>Savanna Technologies Ltd is a software company focused on developing solutions that facilitate operations within the financial services industry.</p>
            </div>
            <div className="unit">
                <h3>SB</h3>
                <h3>Remit</h3>
                <p>SB Remit is a money remittance company trading under Sukate & Bezeboh Ltd. We provide a platform for individuals and businesses to conveniently transfer money.</p>
                <img src={arrowGreen} alt="arrow" />
            </div>
            <div className="unit">
                <h3>SB</h3>
                <h3>Capital</h3>
                <p>SB Capital is a Non-Bank Financial Institution company registered in Cameroon with head office in Akwa, Douala. We currently operate a Bureau de Change, enabling the manual exchange of currencies in a secure office environment.</p>
                <img src={arrowGreen} alt="arrow" />
            </div>
        </div>
    </SubsidiaryStyle>
)

export default Subsidiary

const SubsidiaryStyle = styled.div`
    width: 100%;
    min-height: 40vw;
    padding: 6vw ${({theme}) => theme.padding} 7.5vw;
    background-color: ${({theme}) => theme.colors.sbGreyEC};
    h1{
        margin: 0 0 3vw;
        font-size: 2vw;
        font-weight: medium;
        color: ${({theme}) => theme.colors.sbBlack};
    }
    .subsidiaries{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 3vw;
        .unit{
            background-color: ${({theme}) => theme.colors.sbWhite};
            box-shadow: 0px 12px 24px #00000029;
            /* width: 22vw; */
            padding: 3vw 0 6.5vw 1.5vw;
            position: relative;
            h3{
                font-size: 1.5vw;
                line-height: 1.8vw;
                font-weight: medium;
                color: ${({theme}) => theme.colors.sbGreen};
            }
            p{
                font-size: 1.2vw;
                line-height: 1.6vw;
                font-weight: medium;
                color: ${({theme}) => theme.colors.sbDarkgrey};
                width: 80%;
                /* width: 16.6vw; */
                margin: 2.5vw 0 0;
            }
            img{
                position: absolute;
                height: 60px;
                width: 60px;
                bottom: 0;
                right: 0;
            }
        }
    }

    @media only screen and (min-width: 1920px){
        height: 720px;
        padding: 120px ${({theme}) => theme.maxPadding} 0;
        h1{
            margin: 0 0 54px;
            font-size: 40px;
        }
        .subsidiaries{
            display: flex;
            justify-content: space-between;
            .unit{
                width: 420px;
                padding: 56px 0 125px 30px;
                h3{
                    font-size: 24px;
                    line-height: 30px;
                }
                p{
                    font-size: 18px;
                    line-height: 24px;
                    width: 319px;
                    margin: 48px 0 0;
                }
            }
        }
    }
    @media only screen and (max-width: 767px){
        padding: 40px ${({theme}) => theme.mobilePadding} 70px;
        h1{
            margin: 0 0 24px;
            font-size: 20px;
        }
        .subsidiaries{
            display: block;
            .unit{
                padding: 40px 0 40px 20px;
                margin: 0 0 16px;
                position: relative;
                h3{
                    font-size: 16px;
                    display: inline-block;
                    margin-right: 4px;
                }
                p{
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    width: 100%;
                    margin: 16px 0 0;
                }
                img{
                    display: none;
                }
            }
        }
    }
`;
