import React from 'react'
import styled from 'styled-components'

const Services = () => (
    <ServicesStyle id="services">
        <div className="servicesContent">
            <div className="service">
                <h3>Our Services</h3>
            </div>
            <div className="content">
                <div className="unit">
                    <h5>Bespoke software</h5>
                    <p>Every business has a unique solution. We work with our clients to develop software that fit into their operating model. Our main objective is for our solutions to improve efficiency and increase profitability for our clients.</p>
                </div>
                <div className="unit">
                    <h5>Consulting</h5>
                    <p>Our expert staffs work with small and medium size enterprises in advising them on how to combine technology and local regulatory requirements to facilitate entry into new markets or growth in existing markets.</p>
                </div>
                <div className="unit">
                    <h5>Analytics</h5>
                    <p>We work with our clients in providing technology that enables customer data capture, analyse the data, and generate insights that enable them to stay ahead of competition.</p>
                </div>
            </div>
        </div>
    </ServicesStyle>
)

export default Services

const ServicesStyle = styled.div`
    width: calc(100% - 1vw);
    min-height: 46vw;
    background-color: ${({theme}) => theme.colors.sbYellow};
    position: relative;
    .servicesContent{
        height: 100%;
        width: 100%;
        background-color: ${({theme}) => theme.colors.sbWhite};
        padding: 0 ${({theme}) => theme.padding} 0 calc( ${({theme}) => theme.padding} - 1vw);
        position: absolute;
        top: -1vw;
        right: -1vw;
        display: flex;
        .service{
            width: 30%;
            h3{
                font-size: 2vw;
                font-weight: medium;
                color: ${({theme}) => theme.colors.sbBlack};
                margin: 6vw 0 0;
            }
        }
        .content{
            width: 70%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .unit{
                margin: 0 0 3.4vw;
                border-top: thin solid  ${({theme}) => theme.colors.sbGreyDA};
                padding: 1.6vw 0 0;
                &:first-of-type{
                    border: none;
                    padding: 0;
                }
                h5{
                    font-size: 1.25vw;
                    font-weight: medium;
                    color: ${({theme}) => theme.colors.sbGreen};
                    margin: 0 0 1.6vw;
                }
                p{
                    font-size: 1.25vw;
                    color: ${({theme}) => theme.colors.sbDarkgrey};
                }
            }
        }
    }
    @media only screen and (min-width: 1920px){
        height: 880px;
        width: calc(100% - 20px);
        .servicesContent{
            padding: 0 ${({theme}) => theme.maxPadding} 0 calc(${({theme}) => theme.maxPadding} - 20px);
            position: absolute;
            top: -20px;
            right: -20px;
            .service{
                h3{
                    font-size: 40px;
                    margin: 116px 0 0;
                }
            }
            .content{
            width: 70%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .unit{
                margin: 0 0 64px;
                padding: 32px 0 0;
                h5{
                    font-size: 24px;
                    margin: 0 0 32px;
                }
                p{
                    font-size: 24px;
                }
            }
        }
        }
    }
    @media only screen and (max-width: 767px){
        height: 425px;
        width: calc(100% - 10px);
        .servicesContent{
            padding: 40px ${({theme}) => theme.mobilePadding} 24px calc( ${({theme}) => theme.mobilePadding} - 10px);
            position: absolute;
            top: -10px;
            right: -10px;
            display: flex;
            flex-direction: column;
            .service{
                width: 100%;
                h3{
                    font-size: 20px;
                    margin: 0;
                }
            }
            .content{
                width: 100%;
                justify-content: flex-start;
                .unit{
                    margin: 0;
                    padding: 24px 0 18px;
                    &:first-of-type{
                        padding: 24px 0 18px;
                    }
                    h5{
                        font-size: 16px;
                        margin: 0 0 16px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 599px){
        height: 450px;
    }
    @media only screen and (max-width: 506px){
        height: 480px;
    }
    @media only screen and (max-width: 425px){
        height: 510px;
    }
    @media only screen and (max-width: 397px){
        height: 550px;
    }
    @media only screen and (max-width: 330px){
        height: 580px;
    }
    /* spacing is okay up to 278px  */
`;
