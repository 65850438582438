import {
	createGlobalStyle,
	DefaultTheme,
	ThemeProvider,
} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    list-style-type: none;
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
    font-family: 'Space Grotesk', sans-serif;
  }
`;

declare module 'styled-components' {
	export interface DefaultTheme {
		colors: {
			sbGreen: string;
			sbYellow: string;
			sbWhite: string;
			sbBlack: string;
			sbDarkgrey: string;
			sbGreyEC: string;
			sbGreyDA: string;
			neutral29: string;
			neutral33: string;
			neutral40: string;
		};
        padding: string;
        mobilePadding: string;
        maxPadding: string;
    }
}

const theme: DefaultTheme = {
	colors: {
        sbGreen: '#007B5D',
        sbYellow: '#FCD20F',
        sbWhite: '#FFFFFF',
        sbBlack: '#212121',
        sbDarkgrey: '#707070',
        sbGreyEC: '#ECECEC',
        sbGreyDA: '#DADADA',
        neutral29: '#00000029',
        neutral33: '#00000033',
        neutral40: '#00000040',
	},
    padding: '6%',
    mobilePadding: '24px',
    maxPadding: '272px',
};

export const Theme = ({ children }: { children: JSX.Element }) => (
	<ThemeProvider theme={theme}>{children}</ThemeProvider>
)