import React from 'react'
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import styled from 'styled-components'
import Logo from 'assets/sb/logo-full.png'

const NavStyle = styled.nav`
    background-color: ${({theme}) => theme.colors.sbGreen};
    min-height: 70px;
    height: 5.5vw;
    width: 100%;
    padding: 0 ${({theme}) => theme.padding};
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
        width: 12vw;
        height: 3.5vw;
    }
    .links{
        a{
            display: inline-block;
            height: 50px;
            padding: 0 13px;
            margin: 0 3.5vw 0 0;
            font-size: 1.5vw;
            line-height: 50px;
            font-weight: medium;
            color: ${({theme}) => theme.colors.sbWhite};
            &:last-child{
                background-color: ${({theme}) => theme.colors.sbYellow};
                border-radius: 5px;
                margin: 0;
                color: ${({theme}) => theme.colors.sbBlack};
            }
        }
    }
    @media only screen and (min-width: 1920px) {
        height: 105px;
        padding: 0 ${({theme}) => theme.maxPadding};
        img{
            width: 227px;
            height: 67px;
        }
        .links{
            a{
                margin: 0 60px 0 0;
                font-size: 20px;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 0 ${({theme}) => theme.mobilePadding};
        img{
            width: 140px;
            height: 40px;
        }
        .links{
            a{
                display: none;
                &:last-child{
                    display: inline-block;
                    font-size: 14px;
                    line-height: 24px;
                    padding: 8px 10px;
                    height: fit-content;
                }
            }
        }
    }
    @media only screen and (max-width: 349px) {
        img{
            width: 100px;
            height: 30px;
        }
        .links{
            a{
                &:last-child{
                    font-size: 14px;
                    line-height: 20px;
                    padding: 5px 10px;
                }
            }
        }
    }
`;

export const Navbar = () => {
    return (
        <NavStyle>
            <NavLink to="/">
                <img src={Logo} alt="SB logo" />
            </NavLink>
            <div className="links">
                <NavHashLink to="/#about">About</NavHashLink>
                <NavHashLink to="/#subsidiaries">Subsidiaries</NavHashLink>
                <NavHashLink to="/#services">Services</NavHashLink>
                <NavLink to="/contact">Contact Us</NavLink>
            </div>
        </NavStyle>
    )
}
