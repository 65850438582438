import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom';
import main from 'assets/bk/main.png'
import mainx2 from 'assets/bk/mainx2.png'
import mainMobile from 'assets/bk/mainMobile.png'

const Main = () => (
    <MainStyle>
        <div className="text">
            <div className="textContent">
                <h2>Your Unique Message Goes Here</h2>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
            </div>
        </div>
        <div className="box">
            <div className="boxContent">
                <div className="unit">
                    <h3>Our Mission</h3>
                    <p>Our mission is to build platforms for businesses used in facilitating transfer of money and cross border payments.</p>
                </div>
                <div className="unit">
                    <h3>Our Vision</h3>
                    <p>Our vision is to be the leaders in designing tailored solutions to simplify the flow of funds all around the world.</p>
                </div>
                <NavLink to="/contact">Contact Us</NavLink>
            </div>
        </div>
    </MainStyle>
)

export default Main

const MainStyle = styled.main`
    height: 36vw;
    width: 100%;
    padding: 0 ${({theme}) => theme.padding};
    background-image: url(${main});
    background-size: cover;
    display: flex;
    align-items: center;
    .text{
        width: 54%;
        .textContent{
            width: 28vw;
            color: ${({theme}) => theme.colors.sbWhite};
            h2{
                font-size: 2.5vw;
                line-height: 3.2vw;
                font-weight: medium;
            }
            p{
                font-size: 1vw;
                line-height: 1.5vw;
                margin: 1.6vw 0 0 0;
            }
        }
    }
    .box{
        width: 46%;
        .boxContent{
            padding: 4vw 2vw 4vw 1.5vw;
            width: fit-content;
            border-top: 5px solid ${({theme}) => theme.colors.sbYellow};
            background-color: ${({theme}) => theme.colors.sbWhite};
            .unit{
                h3{
                    color: ${({theme}) => theme.colors.sbGreen};
                    font-size: 1.25vw;
                    font-weight: medium;
                }
                p{
                    color: ${({theme}) => theme.colors.sbDarkgrey};
                    font-size: 1vw;
                    font-weight: 400;
                    width: 20vw;
                    margin: 1vw 0 0;
                }
                &:nth-child(2){
                    margin: 2.8vw 0 2vw;
                }
            }
            a{
                display: inline-block;
                background-color: ${({theme}) => theme.colors.sbYellow};
                color: ${({theme}) => theme.colors.sbBlack};
                padding: 12px;
                border-radius: 8px;
                font-size: 1vw;
            }
        }
    }
    @media only screen and (min-width: 1920px){
        padding: 0 ${({theme}) => theme.maxPadding};
        background-image: url(${mainx2});
        .text{
            .textContent{
                width: 537px;
                h2{
                    font-size: 48px;
                    line-height: 54px;
                }
                p{
                    font-size: 20px;
                    line-height: 30px;;
                    margin: 40px 0 0 0;;
                }
            }
        }
        .box{
            .boxContent{
                padding: 75px 40px 75px 30px;
                .unit{
                    h3{
                        font-size: 24px;
                    }
                    p{
                        font-size: 18px;
                        width: 367px;
                        margin: 1vw 0 0;
                    }
                    &:nth-child(2){
                        margin: 54px 0 40px;
                    }
                }
                a{
                    font-size: 20px;
                }
            }
        }
    }
    @media only screen and (max-width: 767px){
        background-image: url(${mainMobile});
        height: calc((100vw * 212 / 375) + 25px);
        position: relative;
        .text{
            width: 100%;
            .textContent{
                width: 70%;
                h2{
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: medium;
                }
                p{
                    font-size: 14px;
                    line-height: 18px;
                    margin: 16px 0 0;
                }
            }
        }
        .box{
            position: absolute;
            border-radius: 10px;
            box-shadow: 0px 6px 12px #00000040;
            width: calc(100% - (2 * ${({theme}) => theme.mobilePadding}));
            top: calc(100% - (2 * ${({theme}) => theme.mobilePadding} - 10px));
            left: ${({theme}) => theme.mobilePadding};
            z-index: 2;
            .boxContent{
                padding: 60px 0 36px;
                width: 100%;
                border-top: 5px solid ${({theme}) => theme.colors.sbYellow};
                background-color: ${({theme}) => theme.colors.sbWhite};
                .unit{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    h3{
                        font-size: 16px;
                    }
                    p{
                        font-size: 14px;
                        width: 70%;
                        margin: 12px 0 0;
                        text-align: center;
                    }
                    &:nth-child(2){
                        margin: 30px 0 0;
                    }
                }
                a{
                    display: none;
                }
            }
        }
    }
    @media only screen and (max-width: 399px){
        height: calc((100vw * 212 / 375) + 60px);
    }
    @media only screen and (max-width: 359px){
        height: calc((100vw * 212 / 375) + 100px);
    }
    @media only screen and (max-width: 309px){
        height: calc((100vw * 212 / 375) + 150px);
    }
    @media only screen and (max-width: 279px){
        height: calc((100vw * 212 / 375) + 180px);
    }
`;
