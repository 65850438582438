import React from 'react'
import styled from 'styled-components'
import { Founder } from 'components'

const Founders = () => (
    <FoundersStyle>
        <h3>Founders</h3>
        <div className="founders">
            <Founder founder="sukate"/>
            <div className="down">
                <Founder founder="bezeboh"/>
            </div>
        </div>
    </FoundersStyle>
)

export default Founders

const FoundersStyle = styled.div`
    width: 100%;
    min-height: 46vw;
    padding: 8.2vw ${({theme}) => theme.padding} 6.7vw;
    h3{
       font-size: 2vw;
       font-weight: medium;
       color: ${({theme}) => theme.colors.sbBlack};
    }
    .founders{
        margin: 4.5vw 2.7vw 0 3.3vw;
        display: flex;
        justify-content: space-between;
        .down{
            margin: 7.2vw 0 0;
        }
    }
    @media only screen and (min-width: 1920px){
        min-height: 46vw;
        padding: 157px ${({theme}) => theme.maxPadding} 129px;
        h3{
        font-size: 40px;
        }
        .founders{
            margin: 88px 53px 0 64px;
            .down{
                margin: 138px 0 0;
            }
        }
    }
    @media only screen and (max-width: 767px){
        padding: 40px ${({theme}) => theme.mobilePadding} 38px;
        h3{
            font-size: 20px;
        }
        .founders{
            margin: 34px 0 0;
            flex-direction: column;
            justify-content: space-between;
            .down{
                margin: 40px 0 0;
            }
        }
    }
`;
