import React from 'react'
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Logo from 'assets/sb/logo.png'
import Linkedin from 'assets/socials/linkedin.png'
import Twitter from 'assets/socials/twitter.png'
import MeetingRoom from 'assets/bk/meetingRoom.jpeg'

const ContactStyle = styled.div`
    background-color: ${({theme}) => theme.colors.sbGreen};
    height: 40vw;
    width: 100%;
    padding: 0 ${({theme}) => theme.padding};
    display: flex;
    flex-direction: column;
    justify-content: center;
    .top{
        width: 100%;
        /* height: 486px; */
        height: 65%;
        /* margin: 86px 0 0; */
        margin: 4.5vw 0 0;
        display: flex;
        .content{
            width: 47%;
            height: 100%;
            /* padding: 66px 0 46px; */
            padding: 2.5vw 0 1.5vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .contact{
                color: ${({theme}) => theme.colors.sbWhite};
                h3{
                    font-size: 2.1vw;
                    font-weight: 400;
                    margin: 0 0 .6vw;
                }
                p{
                    font-size: 1vw;
                    line-height: 1.5vw;
                    font-weight: 400;
                    width: 21vw;
                }
            }
            .talk{
                color: ${({theme}) => theme.colors.sbWhite};
                h5{
                    font-size: 1.25vw;
                    font-weight: 400;
                }
                p{
                    font-size: 1vw;
                    line-height: 1.5vw;
                    font-weight: 400;
                    width: 12vw;
                    margin: .8vw 0 1.2vw;
                }
                a{
                    background-color: ${({theme}) => theme.colors.sbYellow};
                    color: ${({theme}) => theme.colors.sbBlack};
                    display: inline-block;
                    padding: 1vw 10px;
                    border-radius: 5px;
                    font-size: 1vw;
                }
            }
        }
        .image{
            height: 100%;
            width: 53%;
            background-image: url(${MeetingRoom});
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .bottom{
        /* height: 179px; */
        height: 35%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .unit{
            p, a{
                display: block;
                color: ${({theme}) => theme.colors.sbWhite};
                font-size: 18px;
                line-height: 24px;
                font-size: 1.2vw;
                line-height: 1.8vw;
                .mobile{
                    display: none;
                }
            }
        }
    }
    @media only screen and (min-width: 1920px) {
        padding: 0 ${({theme}) => theme.maxPadding};
        .top{
            .content{
                .contact{
                    h3{
                        font-size: 40px;
                        margin: 0 0 24px;
                    }
                    p{
                        font-size: 18px;
                        line-height: 28px;
                        width: 380px;
                    }
                }
                .talk{
                    h5{
                        font-size: 24px;
                        font-weight: 400;
                    }
                    p{
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: 400;
                        width: 235px;
                        margin: 16px 0 24px;
                    }
                    a{
                        font-size: 18px;
                        padding: 20px 10px;
                    }
                }
            }
        }
        .bottom{
        .unit{
            p, a{
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
    }
    @media only screen and (max-width: 767px){
        padding: 0 ${({theme}) => theme.mobilePadding};
        height: fit-content;
        .top{
            flex-direction: column;
            height: fit-content;
            margin: 60px 0 0;
            .content{
                width: 100%;
                height: fit-content;
                padding: 0;
                .contact{
                    h3{
                        font-size: 20px;
                        font-weight: medium;
                        margin: 0 0 16px;
                    }
                    p{
                        font-size: 14px;
                        line-height: 20px;
                        width: 95%;
                    }
                }
                .talk{
                    color: ${({theme}) => theme.colors.sbWhite};
                    margin: 40px 0;
                    h5{
                        font-size: 20px;
                        font-weight: medium;
                    }
                    p{
                        font-size: 14px;
                        width: 100%;
                        margin: 16px 0 24px;
                    }
                    a{
                        padding: 10px;
                        font-size: 14px;
                    }
                }
            }
            .image{
                height: calc( (100vw - ${({theme}) => theme.mobilePadding}) * 0.53);
                width: 100%;
                background-image: url(${MeetingRoom});
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
        .bottom{
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 24px 0;
            .unit{
                margin: 0 0 16px;
                p, a{
                    display: block;
                    color: ${({theme}) => theme.colors.sbWhite};
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    .mobile{
                        display: inline;
                    }
                }
                p.desktop{
                    display: none;
                }
            }
        }
    }
`;

const Contact = () => (
    <ContactStyle>
        <div className="top">
            <div className="content">
                <div className="contact">
                    <h3>Contact Us</h3>
                    <p>Our team of experts are ready to help your company realise its full potential. Get in touch.</p>
                </div>
                <div className="talk">
                    <h5>Let's talk</h5>
                    <p>Together we can help you achieve more.</p>
                    <NavLink to="/contact">Get In Touch</NavLink>
                </div>
            </div>
            <div className="image"></div>
        </div>
        <div className="bottom">
            <div className="unit">
                <p>Registered in England and Wales: <span className="mobile">12735266</span></p>
                <p className="desktop">12735266</p>
            </div>
            <div className="unit">
                <p>78 Woodlands Way Leeds,</p>
                <p>LS14 2AW</p>
            </div>
            <div className="unit">
                <a href="tel:+443301334158">Phone: +443301334158</a>
                <a href="mailto:info@sukatebezeboh.com">Email: info@sukatebezeboh.com</a>
            </div>
        </div>
    </ContactStyle>
)

const BrandStyle = styled.div`
    background-color: ${({theme}) => theme.colors.sbWhite};
    min-height: 150px;
    width: 100%;
    padding: 0 ${({theme}) => theme.padding};
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
        height: 38px;
        width: 59px;
    }
    .copyright{
        p{
            font-size: 14px;
            line-height: 24px;
            width: 224px;
        }
    }
    .socialContact{
        h5{
            font-size: 18px;
            font-weight: 400;
            color: ${({theme}) => theme.colors.sbGreen};
        }
        .unit{
            margin: 20px 0 0;
            img{
                height: 15px;
                width: 15px;
            }
            a{
                font-size: 14px;
                color: ${({theme}) => theme.colors.sbDarkgrey};
                &:first-of-type{
                    margin: 0 33px 0 24px;
                }
            }
        }
    }
    @media only screen and (min-width: 1920px) {
        padding: 0 ${({theme}) => theme.maxPadding};
    }
    @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 0 30px 25px;
        .copyright{
            p{
                font-size: 14px;
                line-height: 24px;
                width: 270px;
                margin: 25px 0 20px;
            }
        }
        .socialContact{
            h5{
                font-weight: 500;
            }
            .unit{
                margin: 20px 0 0;
                img{
                    height: 15px;
                    width: 15px;
                }
                a{
                    font-size: 14px;
                    color: ${({theme}) => theme.colors.sbDarkgrey};
                    &:first-of-type{
                        margin: 0 16px 0 24px;
                    }
                }
            }
        }
    }
`;

const Brand = () => (
    <BrandStyle>
        <img src={Logo} alt="SB logo" />
        <div className="copyright">
            <p>© Copyright 2021 Sukate & Bezeboh Ltd All Rights Reserved.</p>
        </div>
        <div className="socialContact">
            <h5>Connect with us</h5>
            <div className="unit">
                <img src={Linkedin} alt="linkedin" />
                <a href="https://www.linkedin.com/in/sukate" target="_blank" rel="noopener noreferrer">Sukate</a>
                <a href="https://www.linkedin.com/in/bezeboh" target="_blank" rel="noopener noreferrer">Bezeboh</a>
            </div>
            <div className="unit">
                <img src={Twitter} alt="twitter" />
                <a href="https://twitter.com/sukate" target="_blank" rel="noopener noreferrer">Sukate</a>
                <a href="https://twitter.com/bezeboh" target="_blank" rel="noopener noreferrer">Bezeboh</a>
            </div>
        </div>
    </BrandStyle>
)

const BaseStyle = styled.div`
    width: 100%;
    height: 5px;
    display: flex;
    div{
        width: 100%;
        height: 100%;
        &:first-child{
            background-color: ${({theme}) => theme.colors.sbGreen};
        }
        &:last-child{
            background-color: ${({theme}) => theme.colors.sbYellow};
        }
    }
`;
const Base = () => (
    <BaseStyle>
        <div></div>
        <div></div>
    </BaseStyle>
)

export const Footer = () => {
    return (
        <footer>
            <Contact />
            <Brand />
            <Base />
        </footer>
    )
}
