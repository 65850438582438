import React, {useState} from 'react'
import styled from 'styled-components'
import { useForm, SubmitHandler } from "react-hook-form";
import emailjs from 'emailjs-com';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Founder } from 'components'

type Inputs = {
    firstName: string,
    lastName: string,
    company?: string,
    email: string,
    message: string,
  };

export const Contact = () => {
    const [phoneNumber, setPhoneNumber] = useState({ phone: "" })
    const [isloading, setIsloading] = useState(false)
    const { register, handleSubmit } = useForm<Inputs>();
    const EmailJS = (formInputs: any) => {
        setIsloading(true)
        emailjs.send('dev_test', 'template_tx7uj2u', formInputs, 'user_MxRphF1Mu8SEVB50XBkeE')
        .then((response) => {
        console.log({ response })
        console.log('SUCCESS!', response.status, response.text);
        }, (err) => {
        console.log('FAILED...', err);
        })
        .finally(() => {
            setIsloading(false)
        });
    }
    const onSubmit: SubmitHandler<Inputs> = formData => {
        const payload = {
            ...phoneNumber,
            ...formData
        }
        EmailJS(payload)
    };

    return (
        <ContactSyle>
            <div className="founders">
                <h3>Founders</h3>
                <div className="founder">
                    <Founder founder="sukate"/>
                </div>
                <div className="founder">
                    <Founder founder="bezeboh"/>
                </div>
            </div>
            <div className="form" id="contactform">
                <h3>Send us a message</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="unit">
                        <label>First name</label>
                        <input type="text" placeholder="First name" {...register("firstName", { required: true })} />
                    </div>
                    <div className="unit">
                        <label>Last name</label>
                        <input type="text" placeholder="Last name" {...register("lastName", { required: true })} />
                    </div>
                    <div className="unit">
                        <label>Company (Optional)</label>
                        <input type="text" placeholder="Company name" {...register("company")} />
                    </div>
                    <div className="unit">
                        <label>Email address</label>
                        <input type="email" placeholder="Email address" {...register("email", { required: true })} />
                    </div>
                    <div className="unit">
                        <label>Phone number</label>
                        <PhoneInput
                          country={'cm'}
                          value={phoneNumber.phone}
                          onChange={phone => setPhoneNumber({ phone })}/>
                    </div>
                    <div className="unit">
                        <label>Your message</label>
                        <textarea cols={60} {...register('message', { required: true })}></textarea>
                    </div>
                    {
                        isloading ? (
                            <div className="loading">
                                <Loader type="TailSpin" height={20} width={20} color="black"/>
                            </div>
                        ) : (
                            <input type="submit" value="Send" />
                        )
                    }
                </form>
            </div>
        </ContactSyle>
    )
}


const ContactSyle = styled.div`
    width: 100%;
    padding: 113px ${({theme}) => theme.padding} 177px;
    display: flex;
    .founders, .form{
        width: 50%;
    }
    h3{
        /* font-size: 40px; */
        font-size: 2vw;
        color: ${({theme}) => theme.colors.sbBlack};
    }
    .founders{
        .founder{
            margin: 73px 0 0;
        }
    }
    .form{
        form{
            border-top: thin solid ${({theme}) => theme.colors.sbGreyDA};
            margin: 10px 0 0;
            padding: 20px 0 0;
            .unit{
                display: flex;
                flex-direction: column;
                margin: 20px 0 0;
                label{
                    font-size: 18px;
                    color: ${({theme}) => theme.colors.sbBlack};
                    margin-bottom: 5px;
                }
                input{
                    width: 100%;
                    height: 40px;
                    padding: 0 20px;
                    font-size: 14px;
                }
                input.form-control{
                    padding: 0 20px 0 45px;
                }
                textarea{
                    height: 120px;
                    padding: 10px 20px;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
            input[type=submit]{
                background-color: ${({theme}) => theme.colors.sbYellow};
                border: none;
                padding: 15px 30px;
                margin: 40px 0 0;
                border-radius: 5px;
                font-size: 20px;
                cursor: pointer;
            }
            .loading{
                background-color: ${({theme}) => theme.colors.sbYellow};
                border: none;
                padding: 15px 45px;
                width: max-content;
                margin: 40px 0 0;
                border-radius: 5px;
            }
        }
    }
    @media only screen and (min-width: 1920px){
        padding: 157px ${({theme}) => theme.maxPadding} 129px;
        h3{
            font-size: 40px;
        }

    }
    @media only screen and (max-width: 767px){
        padding: 0 ${({theme}) => theme.mobilePadding} 38px;
        flex-direction: column-reverse;
        .founders, .form{
            width: 100%;
            margin: 60px 0 0;
        }
        h3{
            font-size: 20px;
        }

    }
`;
