import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation} from "react-router-dom";
import { Navbar, Footer } from 'components'
import { Home, Contact } from 'pages';

const BackToTop = () => {
    const urlPath = useLocation()
    useEffect(() => {
      (()=> window.scrollTo(0,0))()
    }, [urlPath])
  
    return null
  }

export const Approutes = () => {
    return (
        <Router>
            <BackToTop />
            <Navbar />
            <Switch>
                <Route path="/" exact> <Home /></Route>
                <Route path="/contact" exact> <Contact /></Route>
                {/* temporary catch all route */}
                <Route path="/" > <Home /></Route>
            </Switch>
            <Footer />
        </Router>
    )
}
