import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom';

const About = () => (
    <AboutStyle id="about">
        <div className="aboutContent">
            <div className="about">
                <h3>About Us</h3>
            </div>
            <div className="content">
                <p>We are a software development company focused on white label platforms for implementation in small and medium sized financial institutions. We work with our clients in designing bespoke solutions that will cater for their specific needs.</p>
                <NavLink to="/contact">Contact Us</NavLink>
            </div>
        </div>
    </AboutStyle>
)

export default About

const AboutStyle = styled.div`
    width: 100%;
    height: 37vw;
    background-color: ${({theme}) => theme.colors.sbGreen};
    position: relative;
    .aboutContent{
        height: 100%;
        width: 100%;
        background-color: ${({theme}) => theme.colors.sbWhite};
        padding: 0 ${({theme}) => theme.padding} 0 calc( ${({theme}) => theme.padding} + 1vw);
        position: absolute;
        top: -1vw;
        left: -1vw;
        display: flex;
        .about{
            width: 30%;
            h3{
                font-size: 2vw;
                font-weight: medium;
                color: ${({theme}) => theme.colors.sbGreen};
                margin: 7.4vw 0 0;
            }
        }
        .content{
            width: 70%;
            p{
                font-size: 2vw;
                line-height: 2.8vw;
                font-weight: medium;
                color: ${({theme}) => theme.colors.sbGreen};
                margin: 11vw 0 3vw;
                width: 47vw;
            }
            a{
                display: inline-block;
                background-color: ${({theme}) => theme.colors.sbYellow};
                color: ${({theme}) => theme.colors.sbBlack};
                padding: 12px;
                border-radius: 8px;
                font-size: 1vw;
            }
        }
    }
    @media only screen and (min-width: 1920px){
        height: 714px;
        .aboutContent{
            padding: 0 ${({theme}) => theme.maxPadding} 0 calc(${({theme}) => theme.maxPadding} + 20px);
            position: absolute;
            top: -20px;
            left: -20px;
            .about{
                h3{
                    font-size: 40px;
                    margin: 143px 0 0;
                }
            }
            .content{
                p{
                    font-size: 40px;
                    line-height: 56px;
                    margin: 215px 0 56px;
                }
                a{
                    font-size: 20px;
                }
            }
        }
    }
    @media only screen and (max-width: 767px){
        /* height: calc(((100vw * 212 / 375) + 20px) + 300px); */
        height: 500px;
        .aboutContent{
            padding: 0 ${({theme}) => theme.mobilePadding} 0 calc( ${({theme}) => theme.mobilePadding} + 10px);
            top: -10px;
            left: -10px;
            flex-direction: column;
            justify-content: flex-end;
            .about{
                width: 100%;
                h3{
                    font-size: 20px;
                    font-weight: medium;
                    color: ${({theme}) => theme.colors.sbGreen};
                    /* margin: 7.4vw 0 0; */
                }
            }
            .content{
                width: 100%;
                margin: 0 0 50px;
                p{
                    font-size: 14px;
                    line-height: 18px;
                    margin: 12px 0 24px;
                    width: 90%;
                }
                a{
                    font-size: 14px;
                }
            }
        }
    }
    @media only screen and (max-width: 610px){
        height: 530px;
    }
    @media only screen and (max-width: 479px){
        height: 550px;
    }
    @media only screen and (max-width: 425px){
        height: 600px;
    }
    @media only screen and (max-width: 374px){
        height: 650px;
    }
    @media only screen and (max-width: 336px){
        height: 680px;
    }
    @media only screen and (max-width: 303px){
        height: 720px;
    }
    
`;
